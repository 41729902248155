<template>
    <div>

        <ConfirmDialog ref="confirmDialog"/>
        <Overlay :overlay="isLoading"/>

        <v-container fluid>

            <v-row justify="center">

                <v-col cols="12">
                    <v-card class="transparent" flat>

                        <v-card-text>

                            <div v-if="!code">

                                <v-row>
                                    <v-col cols="6" lg="3">
                                        <v-card class="rounded-lg shadow" flat>
                                            <v-card-title class="mb-0 pb-0">
                                                <v-skeleton-loader v-if="dataLoading" type="text" width="50"/>
                                                {{ codes.all }}
                                                <v-spacer/>
                                                <v-avatar class="bg-grad-primary" size="45">
                                                    <v-icon color="primary">mdi-data-matrix</v-icon>
                                                </v-avatar>
                                            </v-card-title>
                                            <v-card-text>
                                                Générés
                                                <p class="mb-0 font-weight-medium">
                                                    <v-icon color="primary" dark size="10">mdi-circle</v-icon>
                                                    Total
                                                </p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="6" lg="3">
                                        <v-card class="rounded-lg shadow">
                                            <v-card-title class="mb-0 pb-0">
                                                <v-skeleton-loader v-if="dataLoading" type="text" width="50"/>
                                                {{ codes.used }}
                                                <v-spacer/>
                                                <v-avatar class="bg-grad-primary" size="45">
                                                    <v-icon color="primary">mdi-check-circle</v-icon>
                                                </v-avatar>
                                            </v-card-title>
                                            <v-card-text>
                                                Utilisés
                                                <p class="mb-0 font-weight-medium">
                                                    <v-icon color="primary" size="10">mdi-circle</v-icon>
                                                    Total
                                                </p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="6" lg="3">
                                        <v-card class="rounded-lg shadow">
                                            <v-card-title class="mb-0 pb-0">
                                                <v-skeleton-loader v-if="dataLoading" type="text" width="50"/>
                                                {{ codes.today }}
                                                <v-spacer/>
                                                <v-avatar class="bg-grad-secondary" size="45">
                                                    <v-icon color="secondary">mdi-data-matrix</v-icon>
                                                </v-avatar>
                                            </v-card-title>
                                            <v-card-text>
                                                Générés
                                                <p class="mb-0 font-weight-medium">
                                                    <v-icon color="secondary" size="10">mdi-circle</v-icon>
                                                    Aujourd'hui
                                                </p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="6" lg="3">
                                        <v-card class="rounded-lg shadow">
                                            <v-card-title class="mb-0 pb-0">
                                                <v-skeleton-loader v-if="dataLoading" type="text" width="50"/>
                                                {{ codes.today_used }}
                                                <v-spacer/>
                                                <v-avatar class="bg-grad-secondary" size="45">
                                                    <v-icon color="secondary">mdi-check-circle</v-icon>
                                                </v-avatar>
                                            </v-card-title>
                                            <v-card-text>
                                                Utilisés
                                                <p class="mb-0 font-weight-medium">
                                                    <v-icon color="secondary" size="10">mdi-circle</v-icon>
                                                    Aujourd'hui
                                                </p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                </v-row>

                                <div class="text-center my-5">
                                    <v-btn color="primary"
                                           depressed
                                           fab
                                           x-large
                                           @click="generate">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>

                                </div>

                                <div class="text-center bg-secondary-subtle rounded-lg">
                                    <div class="pa-5">
                                        <img :src="require('@/assets/QRcode.svg')" alt="" width="250"/>

                                        <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4">
                                            Génération code QR
                                        </h1>

                                        <p>
                                            Générer un code QR qui ne peut être utilisé qu'une seule fois !
                                        </p>
                                    </div>
                                </div>

                            </div>


                            <v-row v-else align="center" class="pa-5" justify="center">
                                <v-col cols="12" lg="6">
                                    <div class="text-center bg-primary-subtle rounded-lg pa-10">
                                        <qrcode-vue :size="200"
                                                    :value="code"
                                                    background="transparent"
                                                    foreground="#6f3cfb"/>

                                        <p class="mt-5">
                                          Scannez ce code QR pour rediriger un autre lien.
                                        </p>

                                        <v-btn color="primary"
                                               depressed
                                               @click="code = null">
                                            <v-icon left>mdi-arrow-left</v-icon>
                                            Retour
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>


                        </v-card-text>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
import {HTTP} from "@/http-common";
import QrcodeVue from 'qrcode.vue'

export default {
    components: {
        QrcodeVue,
    },
    data() {
        return {
            dataLoading: false,
            isLoading: false,
            code: null,
            codes: {}
        }
    },
    methods: {
        async generate() {
            if (await this.$refs.confirmDialog.open('', '', {
                agreeText: "Généger",
                color: 'primary',
                icon: 'mdi-information',
            })) {
                this.isLoading = true
                HTTP.post('/animator-view/generate-qrcode').then((res) => {
                    this.code = process.env.VUE_APP_FRONT_URL + '/qrcode/' + res.data.data.code
                    this.isLoading = false
                    this.getData()
                }).catch(err => {
                    this.isLoading = false
                    console.log(err)
                })
            }
        },
        getData() {
            this.dataLoading = true
            HTTP.get('/animator-view/qrcodes').then((res) => {
                this.codes = res.data.data
                this.dataLoading = false
            }).catch(err => {
                this.dataLoading = false
                console.log(err)
            })
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

</style>